#about {
    background: #2B2B2B;
    padding-top: 96px;
    padding-bottom: 66px;
    overflow: hidden;
    margin-top: -1rem !important;
 }
 #about h2 {
    font: 22px/30px 'opensans-bold', sans-serif;
    color: #fff;
    margin-bottom: 12px;
 }
 #about p {
    line-height: 30px;
    color: #7A7A7A;
 }
 #about .profile-pic {
    position: relative;
    border-radius: 100%;
    text-align: center;
 }
 #about .contact-details { width: 41.66667%; }
 #about .download {
    width: 58.33333%;
    padding-top: 6px;
 }
 #about .main-col { padding-right: 5%; }
 #about .download .button {
    margin-top: 6px;
    background: #444;
 }
 #about .download .button:hover {
    background: #fff;
    color: #2B2B2B;
 }
 #about .download .button i {
    margin-right: 15px;
    font-size: 20px;
 }