#resume {
    background:#FFFFFF;
    padding-top: 96px;
    padding-bottom: 66px;
    overflow: hidden;
    margin-top: -1rem !important;
 }

/*----------------------------------------------*/
/*	Skill Bars
/*----------------------------------------------*/

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}
.bars .skills {
  	margin-top: 36px;
   list-style: none;
}
.bars li {
   position: relative;
  	margin-bottom: 60px;
  	background: #ccc;
  	height: 42px;
  	border-radius: 3px;
}
.bars li em {
	font: 15px 'opensans-bold', sans-serif;
   color: #313131;
	text-transform: uppercase;
   letter-spacing: 2px;
	font-weight: normal;
   position: relative;
	top: -36px;
}
.bar-expand {
   position: absolute;
   left: 0;
   top: 0;

   margin: 0;
   padding-right: 24px;
  	background: #313131;
   display: inline-block;
  	height: 42px;
   line-height: 42px;
   border-radius: 3px 0 0 3px;
}

.css {
  	width: 90%;
  	-moz-animation: css 2s ease;
  	-webkit-animation: css 2s ease;
}
.javascript {
   width: 85%;
   -moz-animation: javascript 2s ease;
   -webkit-animation: javascript 2s ease;
}
.programming {
  	width: 83%;
  	-moz-animation: programming 2s ease;
  	-webkit-animation: programming 2s ease;
}
.responsability {
  	width: 93%;
  	-moz-animation: responsability 2s ease;
  	-webkit-animation: responsability 2s ease;
}
.creativity {
   width: 80%;
   -moz-animation: creativity 2s ease;
   -webkit-animation: creativity 2s ease;
}
.multitask {
   width: 85%;
   -moz-animation: multitask 2s ease;
   -webkit-animation: multitask 2s ease;
}

@-moz-keyframes css {
  0%   { width: 0px;  }
  100% { width: 90%;  }
}
@-moz-keyframes javascript {
  0%   { width: 0px;  }
  100% { width: 85%;  }
}
@-moz-keyframes programming {
  0%   { width: 0px;  }
  100% { width: 83%;  }
}
@-moz-keyframes responsability {
  0%   { width: 0px;  }
  100% { width: 93%;  }
}
@-moz-keyframes creativity {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}
@-moz-keyframes multitask {
  0%   { width: 0px;  }
  100% { width: 85%;  }
}


@-webkit-keyframes css {
  0%   { width: 0px;  }
  100% { width: 90%;  }
}
@-webkit-keyframes javascript {
  0%   { width: 0px;  }
  100% { width: 85%;  }
}
@-webkit-keyframes programming {
  0%   { width: 0px;  }
  100% { width: 83%;  }
}
@-webkit-keyframes responsability {
  0%   { width: 0px;  }
  100% { width: 93%;  }
}
@-webkit-keyframes creativity {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}
@-webkit-keyframes multitask {
  0%   { width: 0px;  }
  100% { width: 85%;  }
}
