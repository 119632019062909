/*
=====================================================================

   TOC:
   a. General Styles
   b. Header Styles
   c. About Section
   d. Resume Section
   e. Portfolio Section
   f. Call To Action Section
   g. Testimonials Section
   h. Contact Section
   i. Footer

===================================================================== */

/* ------------------------------------------------------------------ */
/* a. General Styles
/* ------------------------------------------------------------------ */

body { background: #0f0f0f; }

/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

header {
   position: relative;
   height: 800px;
   min-height: 500px;
   width: 100%;
  
   background-size: cover !important;
	-webkit-background-size: cover !important;
   text-align: center;
   overflow: hidden;
}

/* vertically center banner section */
header:before {
   content: '';
   display: inline-block;
   vertical-align: middle;
   height: 100%;
}
header .banner {
   display: inline-block;
   vertical-align: middle;
   margin: 0 auto;
   width: 85%;
   padding-bottom: 30px;
   text-align: center;
}

header .banner-text { width: 100%; }
header .banner-text h1 {
   font: 90px/1.1em 'opensans-bold', sans-serif;
   color: #fff;
   letter-spacing: -2px;
   margin: 0 auto 18px auto;
   text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
}
header .banner-text h3 {
   font: 18px/1.9em 'librebaskerville-regular', serif;
   color: #A8A8A8;
   margin: 0 auto;
   width: 70%;
   text-shadow: 0px 1px 2px rgba(0, 0, 0, .5);
}
header .banner-text h3 span,
header .banner-text h3 a {
   color: #fff;
}
header .banner-text hr {
   width: 60%;
   margin: 18px auto 24px auto;
   border-color: #2F2D2E;
   border-color: rgba(150, 150, 150, .1);
}

/* header social links */
header .social {
   margin: 24px 0;
   padding: 0;
   font-size: 30px;
   text-shadow: 0px 1px 2px rgba(0, 0, 0, .8);
}
header .social li {
   display: inline-block;
   margin: 0 15px;
   padding: 0;
}
header .social li a { color: #fff; }
header .social li a:hover { color: #11ABB0; }

/* scrolldown link */
header .scrolldown a {
   position: absolute;
   bottom: 30px;
   left: 50%;
   margin-left: -29px;
   color: #fff;
   display: block;
   height: 42px;
   width: 42px;
   font-size: 42px;
   line-height: 42px;
   color: #fff;
   border-radius: 100%;

   -webkit-transition: all .3s ease-in-out;
   -moz-transition: all .3s ease-in-out;
   -o-transition: all .3s ease-in-out;
   transition: all .3s ease-in-out;
}
header .scrolldown a:hover { color: #11ABB0; }

/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap ul, #nav-wrap li, #nav-wrap a {
	 margin: 0;
	 padding: 0;
	 border: none;
	 outline: none;
}

/* nav-wrap */
#nav-wrap {
   font: 12px 'opensans-bold', sans-serif;
   width: 100%;
   text-transform: uppercase;
   letter-spacing: 2.5px;
   margin: 0 auto;
   z-index: 100;
   position: fixed;
   left: 0;
   top: 0;
}
.opaque { background-color: #333; }

/* hide toggle button */
#nav-wrap > a.mobile-btn { display: none; }

ul#nav {
   min-height: 48px;
   width: auto;

   /* center align the menu */
   text-align: center;
}
ul#nav li {
   position: relative;
   list-style: none;
   height: 48px;
   display: inline-block;
}

/* Links */
ul#nav li a {

/* 8px padding top + 8px padding bottom + 32px line-height = 48px */

   display: inline-block;
   padding: 8px 13px;
   line-height: 32px;
	text-decoration: none;
   text-align: left;
   color: #fff;

	-webkit-transition: color .2s ease-in-out;
	-moz-transition: color .2s ease-in-out;
	-o-transition: color .2s ease-in-out;
	-ms-transition: color .2s ease-in-out;
	transition: color .2s ease-in-out;
}

ul#nav li a:active { background-color: transparent !important; }
ul#nav li.current a { color: #F06000; }



/* ------------------------------------------------------------------ */
/* e. Portfolio Section
/* ------------------------------------------------------------------ */

#portfolio {
   background: #ebeeee;
   padding-top: 90px;
   padding-bottom: 60px;
}
#portfolio h1 {
   font: 15px/24px 'opensans-semibold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 1px;
   text-align: center;
   margin-bottom: 48px;
   color: #95A3A3;
}

/* Portfolio Content */
#portfolio-wrapper .columns { margin-bottom: 36px; }
.portfolio-item .item-wrap {
   background: #fff;
   overflow: hidden;
   position: relative;

   -webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.portfolio-item .item-wrap a {
   display: block;
   cursor: pointer;
}

/* overlay */
.portfolio-item .item-wrap .overlay {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;

	opacity: 0;
	-moz-opacity: 0;
	filter:alpha(opacity=0);

   -webkit-transition: opacity 0.3s ease-in-out;
	-moz-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;

//    background: url(../images/overlay-bg.png) repeat;
}
.portfolio-item .item-wrap .link-icon {
   display: block;
   color: #fff;
   height: 30px;
   width: 30px;
   font-size: 18px;
   line-height: 30px;
   text-align: center;

   opacity: 0;
	-moz-opacity: 0;
	filter:alpha(opacity=0);

   -webkit-transition: opacity 0.3s ease-in-out;
	-moz-transition: opacity 0.3s ease-in-out;
	-o-transition: opacity 0.3s ease-in-out;
	transition: opacity 0.3s ease-in-out;

   position: absolute;
   top: 50%;
   left: 50%;
   margin-left: -15px;
   margin-top: -15px;
}
.portfolio-item .item-wrap img {
   vertical-align: bottom;
}
.portfolio-item .portfolio-item-meta { padding: 18px }
.portfolio-item .portfolio-item-meta h5 {
   font: 14px/21px 'opensans-bold', sans-serif;
   color: #fff;
}
.portfolio-item .portfolio-item-meta p {
   font: 12px/18px 'opensans-light', sans-serif;
   color: #c6c7c7;
   margin-bottom: 0;
}

/* on hover */
.portfolio-item:hover .overlay {
	opacity: 1;
	-moz-opacity: 1;
	filter:alpha(opacity=100);
}
.portfolio-item:hover .link-icon {
   opacity: 1;
	-moz-opacity: 1;
	filter:alpha(opacity=100);
}

/* popup modal */
.popup-modal {
	max-width: 550px;
	background: #fff;
	position: relative;
	margin: 0 auto;
}
.popup-modal .description-box { padding: 12px 36px 18px 36px; }
.popup-modal .description-box h4 {
   font: 15px/24px 'opensans-bold', sans-serif;
	margin-bottom: 12px;
   color: #111;
}
.popup-modal .description-box p {
	font: 14px/24px 'opensans-regular', sans-serif;
   color: #A1A1A1;
   margin-bottom: 12px;
}
.popup-modal .description-box .categories {
   font: 11px/21px 'opensans-light', sans-serif;
   color: #A1A1A1;
   text-transform: uppercase;
   letter-spacing: 2px;
   display: block;
   text-align: left;
}
.popup-modal .description-box .categories i {
   margin-right: 8px;
}
.popup-modal .link-box {
   padding: 18px 36px;
   background: #111;
   text-align: left;
}
.popup-modal .link-box a {
   color: #fff;
	font: 11px/21px 'opensans-bold', sans-serif;
	text-transform: uppercase;
   letter-spacing: 3px;
   cursor: pointer;
}
.popup-modal a:hover {	color: #00CCCC; }
.popup-modal a.popup-modal-dismiss { margin-left: 24px; }


/* fadein/fadeout effect for modal popup
/* ------------------------------------------------------------------ */

/* content at start */
.mfp-fade.mfp-wrap .mfp-content .popup-modal {
   opacity: 0;
   -webkit-transition: all 200ms ease-in-out;
	-moz-transition: all 200ms ease-in-out;
	-o-transition: all 200ms ease-in-out;
	-ms-transition: all 200ms ease-in-out;
   transition: all 200ms ease-in-out;
}
/* content fadein */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
   opacity: 1;
}
/* content fadeout */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
   opacity: 0;
}

/* ------------------------------------------------------------------ */
/* f. Call To Action Section
/* ------------------------------------------------------------------ */

#call-to-action {
   background: #212121;
   padding-top: 66px;
   padding-bottom: 48px;
}
#call-to-action h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
}
#call-to-action h1 span { display: none; }
#call-to-action .header-col h1:before {
   font-family: 'FontAwesome';
   content: "\f0ac";
	padding-right: 10px;
	font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #fff;
}
#call-to-action .action {
   margin-top: 12px;
}
#call-to-action h2 {
   font: 28px/36px 'opensans-bold', sans-serif;
   color: #EBEEEE;
   margin-bottom: 6px;
}
#call-to-action h2 a {
   color: inherit;
}
#call-to-action p {
   color: #636363;
   font-size: 17px;
}
/*#
call-to-action .button {
	color:#fff;
   background: #0D0D0D;
}
*/
#call-to-action .button:hover,
#call-to-action .button:active {
   background: #FFFFFF;
   color: #0D0D0D;
}
#call-to-action p span {
	font-family: 'opensans-semibold', sans-serif; 
	color: #D8D8D8;
}

/* ------------------------------------------------------------------
/* g. Testimonials
/* ------------------------------------------------------------------ */

#testimonials {
//    background: #1F1F1F url(../images/testimonials-bg.jpg) no-repeat center center;
   background-size: cover !important;
	-webkit-background-size: cover !important;
   background-attachment: fixed;

   position: relative;
   min-height: 200px;
   width: 100%;
   overflow: hidden;
}
#testimonials .text-container {
   padding-top: 96px;
   padding-bottom: 66px;
}
#testimonials h1 {
   font: 18px/24px 'opensans-bold', sans-serif;   
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
}
#testimonials h1 span { display: none; }
#testimonials .header-col { padding-top: 9px; }
#testimonials .header-col h1:before {
   font-family: 'FontAwesome';
   content: "\f10d";
	padding-right: 10px;
	font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #fff;
}

/*	Blockquotes */
#testimonials blockquote {
   margin: 0 0px 30px 0px;
   padding-left: 0;
   position: relative;
   text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}
#testimonials blockquote:before { content: none; }
#testimonials blockquote p {
   font-family: 'librebaskerville-italic', serif;
   padding: 0;
   font-size: 24px;
   line-height: 48px;
   color: #fff
}
#testimonials blockquote cite {
   display: block;
   font-size: 12px;
   font-style: normal;
   line-height: 18px;
   color: #fff;
}
#testimonials blockquote cite:before { content: "\2014 \0020"; }
#testimonials blockquote cite a,
#testimonials blockquote cite a:visited { color: #8B9798; border: none }

/* Flex Slider
/* ------------------------------------------------------------------ */

/* Reset */
.flexslider a:active,
.flexslider a:focus  { outline: none; }
.slides,
.flex-control-nav,
.flex-direction-nav { margin: 0; padding: 0; list-style: none; }
.slides li { margin: 0; padding: 0;}

/* Necessary Styles */
.flexslider {
   position: relative;
   zoom: 1;
   margin: 0;
   padding: 0;
}
.flexslider .slides { zoom: 1; }
.flexslider .slides > li { position: relative; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides > li { display: none; -webkit-backface-visibility: hidden; }
/* Suggested container for slide animation setups. Can replace this with your own */
.flex-container { zoom: 1; position: relative; }

/* Clearfix for .slides */
.slides:before,
.slides:after {
   content: " ";
   display: table;
}
.slides:after {
   clear: both;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child { display: block; }

/* Slider Styles */
.slides { zoom: 1; }
.slides > li {
   /*margin-right: 5px; */
   overflow: hidden;
}

/* Control Nav */
.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -20px;
    text-align: left;
}
.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}
.flex-control-paging li a {
    width: 12px;
    height: 12px;
    display: block;
    background: #ddd;
    background: rgba(255, 255, 255, .3);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    box-shadow: inset 0 0 3px rgba(255, 255, 255, .3);
}
.flex-control-paging li a:hover {
    background: #CCC;
    background: rgba(255, 255, 255, .7);
}
.flex-control-paging li a.flex-active {
    background: #fff;
    background: rgba(255, 255, 255, .9);
    cursor: default;
}
