#message-warning,
#message-success {
  display: none;
  background: #0f0f0f;
  padding: 24px 24px;
  margin-bottom: 36px;
  width: 65%;
  margin-left: 26%;
}
#message-warning {
  color: #d72828;
}
#message-success {
  color: #11abb0;
}

#message-warning i,
#message-success i {
  margin-right: 10px;
}
#image-loader {
  display: none;
  position: relative;
  left: 18px;
  top: 17px;
}

/* Twitter Feed */
#twitter {
  margin-top: 12px;
  padding: 0;
}
#twitter li {
  margin: 6px 0px 12px 0;
  line-height: 30px;
}
#twitter li span {
  display: block;
}
#twitter li b a {
  font: 13px/36px "opensans-regular", Sans-serif;
  color: #474747 !important;
  border: none;
}

/* ------------------------------------------------------------------ */
/* i. Footer
/* ------------------------------------------------------------------ */

.followme {
  color: white;
  padding-bottom: 40px;
  font: 19.8px/1px "opensans-bold", sans-serif;
}
footer {
  padding-top: 48px;
  margin-bottom: 48px;
  color: #303030;
  font-size: 14px;
  text-align: center;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

footer a,
footer a:visited {
  color: #525252;
}
footer a:hover,
footer a:focus {
  color: #fff;
}

/* copyright */
footer .copyright {
  margin: 0;
  padding: 0;
  color: #525252;
}
footer .copyright li {
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 24px;
}
.ie footer .copyright li {
  display: inline;
}
footer .copyright li:before {
  content: "\2022";
  padding-left: 10px;
  padding-right: 10px;
  color: #095153;
}
footer .copyright li:first-child:before {
  display: none;
}

/* social links */
footer .social-links {
  margin: 18px 0 30px 0;
  padding: 0;
  font-size: 30px;
}
footer .social-links li {
  display: inline-block;
  margin: 0;
  padding: 0;
  margin-left: 42px;
  color: #f06000;
}

footer .social-links li:first-child {
  margin-left: 0;
}

/* Go To Top Button */
#go-top {
  position: absolute;
  top: -24px;
  left: 50%;
  margin-left: -30px;
}
#go-top a {
  text-decoration: none;
  border: 0 none;
  display: block;
  width: 60px;
  height: 60px;
  background-color: #525252;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  color: #fff;
  font-size: 21px;
  line-height: 60px;
  border-radius: 100%;
}
#go-top a:hover {
  background-color: #0f9095;
}
